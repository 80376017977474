<template>
	<div style="height: 100vh" class="m-5">
		<div class="columns is-centered is-vcentered">
			<div class="column is-4-desktop is-6-tablet is-full-mobile">
				<div class="card">
					<div
						class="card-header"
						style="
							background: linear-gradient(
								89.31deg,
								#211895 1.38%,
								#904bff 97.6%
							)!important;
						">
						<div
							class="card-header-title is-flex is-justify-content-space-between is-align-items-center">
							<div
								v-if="bankList && bankList.length > 0"
								class="card-header-title is-flex is-justify-content-space-between is-align-items-center">
								<img width="150" :src="`/logos/${site}/logo.svg`" />
								<img :width="transaction.withdrawType === 'TETHER' ? 75: 150" :src="getLogo.logo" />
							</div>
						</div>
					</div>
					<div class="card-content is-centered">
						<b-field label="Hesap Numarası">
							<b-input
								v-model="transaction.iban"
								disabled
								expanded />
						</b-field>
						<b-field label="Alıcı Ad & Soyad">
							<b-input
								v-model="transaction.accountName"
								disabled
								expanded />
						</b-field>
						<b-field label="Tutar(₺)" v-if="transaction.total">
							<b-input
								v-model="transaction.total"
								disabled
								expanded />
						</b-field>
						<hr />
						<p
							class="mb-3 notification has-background-success-light p-3">
							İşlem kontrol edildikten sonra bakiyenize
							yansıyacaktır. Lütfen sayfadan ayrılmayınız
						</p>
						<hr />
						<b-button
							type="is-danger"
							expanded
							size="is-medium"
							@click="windowClose"
							>Kapat</b-button
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { TransactionService, IntegrationService } from "@/services"
	export default {
		name: "WithdrawPay",
		data() {
			return {
				transaction: {},
				loading: false,
				interval: null,
				bankList: [],
			}
		},
		mounted() {
			const html = document.getElementsByTagName("html")[0]
			html.classList = []
			this.init()
			this.interval = setInterval(() => {
				this.init()
			}, 10000)
		},
		methods: {
			async getBankList() {
				this.bankList = []
				const bankListResponse = await IntegrationService.bankList()
				this.bankList = bankListResponse.data
			},
			init() {
				TransactionService.check(this.$route.params.id)
					.then(({ data }) => {
						this.transaction = data
						this.getBankList()
						if (data.status === "SUCCESSFUL") {
							clearInterval(this.interval)
							if (data.successRedirectUrl)
								window.location.href = data.successRedirectUrl
							else this.$router.push({ name: "paymentSuccess" })
						}
						if (data.status === "UNSUCCESSFUL") {
							clearInterval(this.interval)
							if (data.failRedirectUrl)
								window.location.href = data.failRedirectUrl
							else this.$router.push({ name: "paymentError" })
						}
					})
					.catch((err) => {
						this.$buefy.toast.open({
							message: err.message,
							position: "is-top-right",
							type: "is-danger",
						})
						this.$router.push({ name: "paymentError" })
					})
			},
			copy() {
				const copyText = document.getElementById("bankAccount")
				copyText.select()
				copyText.setSelectionRange(0, 99999) /* For mobile devices */
				navigator.clipboard.writeText(copyText.value)
				this.$buefy.toast.open({
					duration: 3000,
					message: "Kopyalandı!",
					position: "is-bottom",
					type: "is-success",
				})
			},
		},
		computed: {
			getLogo() {
				let logo =
					process.env.VUE_APP_SITE_URL + "logos/banks/papara.png"
				if (this.transaction.withdrawType === "TETHER")
					logo =
						process.env.VUE_APP_SITE_URL +
						"logos/" +
						this.site +
						"/crypto.svg"
				if (this.transaction.withdrawType === "PAYFIX")
					logo =
						process.env.VUE_APP_SITE_URL + "logos/banks/payfix.png"
				if (
					this.transaction.withdrawType === "BANK_TRANSFER" ||
					this.transaction.withdrawType === "FOREX"
				) {
					logo = this.bankList.find(
						(item) =>
							item.code === this.transaction.iban.slice(6, 9)
					).logo || { logo: null }
				}
				return { logo }
			},
		},
		beforeDestroy() {
			clearInterval(this.interval)
		},
	}
</script>

<style>
	.qrcode > canvas {
		border: 6px solid #ddd;
		padding: 0.5rem;
		border-radius: 12px;
	}

	.input[type="text"]::placeholder {
		color: #000000 !important;
	}

	.input[type="text"] {
		color: #000000 !important;
	}
</style>
